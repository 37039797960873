import React from 'react';
import _ from 'lodash';
import {ThemeProvider} from 'styled-components';
import sho from './sho';
import nrk from './nrk';
import nxs from './nxs/nxs';
import nxs2 from './nxs/nxs2';
import nxs3 from './nxs/nxs3';
import azn from './azn';
import fsh from './fsh';
import hum from './hum/hum';
import hum2 from './hum/hum2';
import hum3 from './hum/hum3';
import hum4 from './hum/hum4';
import amf from './amf';
import eha from './eha';

function getTheme(theme, fallback = undefined) {
  // todo: We may eventually want to change this so each tenant has their own switch with all their themes
  switch (theme) {
    case 'sho':
      return sho;
    case 'nrk':
      return nrk;
    case 'azn':
      return azn;
    case 'fsh':
      return fsh;
    case 'hum':
      return hum;
    case 'hum2':
      return hum2;
    case 'hum3':
      return hum3;
    case 'hum4':
      return hum4;
    case 'amf':
      return amf;
    case 'eha':
      return eha;
    case 'nxs':
      return nxs;
    case 'nxs2':
      return nxs2;
    case 'nxs3':
      return nxs3;
    default:
      return !_.isNil(fallback) ? getTheme(fallback) : nxs;
  }
}

const Theme = ({store, children, themeFromState}) => (
  <ThemeProvider theme={() => {

    let theme, fallbackTheme;
    if (!_.isNil(store)) {
      const state = store.getState();
      fallbackTheme = state.reducer.formContext.defaultTheme || 'sho'
      theme = state.reducer.theme || fallbackTheme;
    } else {
      theme = themeFromState;
    }

    return getTheme(theme, fallbackTheme);
    }
  }>{children}</ThemeProvider>
);

export default Theme;
