import _ from 'lodash';
import {is_gtm_loaded} from '../helpers';
import {getFormData, getDonationProduct, isProductForm, getProducts, paymentMethodMap} from '../helpers';

export function gaStep4(formContext, callback) {
  return (dispatch, getState) => {
    const state = getState();
    const data = getFormData(state);
    const amount = data.showCustomAmount ? data.customAmount_m : data.amount_m;

    if (!amount || !formContext) {
      if (_.isFunction(callback)) return callback();
      return;
    }

    const paymentMethod = paymentMethodMap[data.paymentMethod] || 'machtiging';

    if (isProductForm(formContext)) {
      window.dataLayer.push({
        'event': 'transactionSend',
        'ecommerce': {
          'purchase': {
            'actionField': {
              id: data.referenceKey,
              affiliation: 'Online Store',
              revenue: data.amount_m,
              tax: '0.00',
              shipping: '0.00',
              action: 'purchase'
            },
            products: getProducts(formContext, state)
          }
        }
      });

      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {
              'step': 4,
              'option': paymentMethod
            },
            products: getProducts(formContext, state)
          }
        }
      });
    } else {
      const ecomEvent = {
        'ecommerce': {
          'purchase': {
            'actionField': {
              id: data.referenceKey,
              affiliation: 'donatiemodule',
              revenue: amount,
              tax: '0.00'
            },
            'products': getDonationProduct(formContext, state)
          }
        },
        'event': 'ecomPurchase'
      };

      if (!is_gtm_loaded()) {
        if (_.isFunction(callback)) return callback();
        return;
      }

      if (_.isFunction(callback)) {
        ecomEvent.eventCallback = () => {
          callback();
        };
        ecomEvent.eventTimeout = 2000;

        // Final timeout to make sure it works
        setTimeout(callback, 3000);
      }
      window.dataLayer.push(ecomEvent);
    }

    if (data.email) {
      const utf8 = new TextEncoder().encode(data.email);
      return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');

        window.dataLayer.push({
          'email': hashHex,
          'event': 'fireEnhancedConversionTag'
        });
      });
    }
  };
}
